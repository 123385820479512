<template>
  <div class="wrapper">
    <el-table
      v-loading="loading"
      :data="tableList"
      border
      :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }"
      style="width: 100%">
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="60">
      </el-table-column>
      <el-table-column
        align="center"
        label="学年">
          <span slot-scope="scope">{{scope.row.value.substr(0,11)}}</span>
      </el-table-column>
      <el-table-column
        align="center"
        label="学期">
        <span slot-scope="scope">{{scope.row.value.substr(12)}}</span>
      </el-table-column>
      <!-- <el-table-column
        prop="semester"
        label="综合等级">
      </el-table-column> -->
      <el-table-column
        align="center"
        prop="paperCount"
        label="评价表数量">
      </el-table-column>
      <el-table-column
        align="center"
        prop="paperScore"
        label="评价表分值">
      </el-table-column>
      <el-table-column
        align="center"
        prop="awardScore"
        label="奖励得分">
      </el-table-column>
      <el-table-column
        align="center"
        prop="punishScore"
        label="惩罚扣分">
      </el-table-column>
      <el-table-column
        align="center"
        prop="endScore"
        label="最后得分">
      </el-table-column>
      <el-table-column
        width="100"
        align="center"
        label="操作">
        <template slot-scope="score">
          <el-button type="text" @click="toDetail(score.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :page-size="violateQuery.pageSize"
      :current-page="violateQuery.pageNo"
      @current-change="violateTableChange"
      :total="violateTableTotal">
    </el-pagination> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableList:[],
    }
  },
  mounted(){
    this.getTable()
  },
  methods: {
    getTable(){
      this.loading = true
      this.$get('evas/awardRecords/statics/getYearSemesterList').then(res=>{
        // console.log(res,'======resdfefed')
        this.tableList = res.result
      }).finally(()=>{
        this.loading = false
      })
    },
    toDetail(row){
      this.$router.push({
        name:'studentEvaluateReportDetail',
        query:{
          year:row.key,
          semester:row.semester,
          paperScore: row.paperScore
        }
      })

    }
  },

}
</script>

<style lang="scss" scoped>
.wrapper{
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  min-height: 800px;
}
</style>
